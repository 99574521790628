import { $apollo } from "@/plugins/apollo";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep, merge } from "lodash-es";
import get from "@/gql/page/get.gql";
import * as Sentry from "@sentry/browser";

import TEST_MODULE from "@/components/TestModule.vue";
const HAS_TEST_MODULE = false;

if (HAS_TEST_MODULE) {
  window["pm-test-module"] = TEST_MODULE;
}
const testModule = {
  segments: [],
  data: {
    tags: [],
    name: "test-module",
    uuid: "1234567890",
    action: "keep",
    by: "user",
    category: "pagemaker",
  },
};

function validate(res) {
  let error = false;
  const keys = [
    "colors",
    "css",
    "fonts",
    "hash",
    "modules",
    "type",
    "variables",
  ];

  keys.forEach((key) => {
    if (!res[key]) {
      console.error("Invalid response received. Missing key:", key);
    }
    error = true;
  });
  return error;
}

function reportModules(modules) {
  const duplicates = modules
    .map((module) => module.uuid)
    .filter((uuid, index, a) => a.indexOf(uuid) !== index);
  if (duplicates.length) {
    Sentry.captureException(
      new Error("Duplicate UUIDs Found: " + duplicates.join(","))
    );
  }
}

export default ({ id, templateId, campaignId, workspaceId }) => {
  return $apollo
    .query(get, {
      id,
      templateId,
      campaignId,
      workspaceId,
    })
    .then(({ data }) => {
      const {
        defaultColors: { value: defaultColors },
        defaultFonts: { value: defaultFonts },
        commonJs: { value: commonJs },
        page: {
          name: pageName,
          slug,
          draftUrl,
          shopifyUrl,
          campaignCss,
          campaign: {
            hash,
            name: campaignName,
            type,
            endDate,
            amazonProduct: {
              name: productName,
              url,
              discountPercentage,
              manufacturer,
              desc,
              features,
              productFeatures,
              reviewCount,
              asin,
              listPrice,
              salePrice,
              currency,
              media,
              marketplace,
              productRating,
              reviews
            },
          },
          modules: originalModules,
          template: { colors, fonts, js },
        },
      } = data;

      /**
       * Preparing Modules
       */
      const reversedModules = cloneDeep(originalModules).reverse();

      /**
       * Filter out modules which have same UUID
       * Technically this should not happen but in some cases if multiple modules are added with same UUID
       * This will make sure they are filtered before loaded in editor
       */
      const uniqueModules = Array.from(
        new Set(originalModules.map((module) => module.uuid))
      ).map((uuid) => {
        /**
         * Reversing the modules while finding the unique modules.
         * This is due to API that considers the latest entry in modules.
         */
        return reversedModules.find((module) => module.uuid === uuid);
      });

      const modules = uniqueModules.map((module) => {
        const { uuid, name, alias, source, category, id, tags, segments } =
          module;
        return {
          segments,
          data: {
            uuid: uuid || uuidv4(),
            name,
            alias,
            by: source,
            category,
            id,
            tags: tags?.map((item) => item.name) || [],
            action: "keep",
            status: "loading",
          },
        };
      });

      if (HAS_TEST_MODULE) {
        modules.push(testModule);
      }

      const res = {
        modules,
        name: pageName,
        slug,
        draftUrl,
        shopifyUrl,
        campaignCss,
        commonJs,
        campaign: {
          type,
          name: campaignName,
          hash,
        },
        template: {
          colors: merge({}, defaultColors, colors?.value),
          fonts: merge({}, defaultFonts, fonts?.value),
          js
        },
        variables: {
          name: campaignName,
          productName,
          url,
          discountPercentage,
          manufacturer,
          desc,
          features,
          productFeatures,
          endDate: endDate * 1000,
          reviewCount,
          asin,
          listPrice,
          salePrice,
          currency,
          media,
          marketplace,
          productRating,
          reviews
        },
      };

      reportModules(originalModules);
      return res;
    });
};
