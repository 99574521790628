import Vue from "vue";
import VTooltip from "v-tooltip";
import VueSlider from "vue-slider-component";
import PortalVue from "portal-vue";
import VueList from "@7span/vue-list";
import vClickOutside from "v-click-outside";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { version } from "../../package.json";

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email, min, regex } from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en";

const rules = { required, email, min, regex };

for (let rule in rules) {
  extend(rule, {
    ...rules[rule], // add the rule
    message: en.messages[rule], // add its message
  });
}

extend("url", {
  validate: (value) => {
    const regex =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
    return (
      value.startsWith("#") ||
      value.match(new RegExp(regex)) ||
      "The URL is invalid. URL must start with http://, https:// or #"
    );
  },
});

extend("lowerCase", {
  validate: (value) => {
    const regex = /^[a-z]+$/;
    if (value.match(new RegExp(regex))) {
      return true;
    } else {
      return "Only lowercase alphabets (a-z) are allowed.";
    }
  },
});

extend("fieldName", {
  validate: (value, data) => {
    if (data.includes(value)) {
      return "The field with this name already exists. All the field names must be unique.";
    }
    return true;
  },
});

extend("domain", {
  validate: (value) => {
    return !(
      value.startsWith("http:") ||
      value.startsWith("https:") ||
      value.startsWith("www.") ||
      value.startsWith(".") ||
      value.endsWith(".") ||
      value.includes("/") ||
      value.indexOf(".") < 0 ||
      value.indexOf(" ") >= 0
    );
  },
});

extend("subdomain", {
  validate: (value) => {
    return !(
      value == "www" ||
      value.indexOf(" ") >= 0 ||
      value.includes("/") ||
      value.indexOf(".") >= 0
    );
  },
});

Vue.use(VTooltip);
Vue.component("VueSlider", VueSlider);
Vue.use(PortalVue);
Vue.use(VueList, {});
Vue.use(vClickOutside);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

if (import.meta.env.PROD) {
  Sentry.init({
    Vue,
    dsn: "https://ae2f776c6d424050a4ef6eff6e26c9e3@o416535.ingest.sentry.io/5998832",

    integrations: [
      new Sentry.Replay(),
      new BrowserTracing({
        // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "editor.pagemaker.local",
          "editor.pagemaker.io",
          "editor.pagemaker.dev",
        ],
      }),
    ],
    // This sets the sample rate to be 20%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.2,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    logErrors: true,
    release: `pagemaker-editor-${version}`,
  });
}
