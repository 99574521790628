import Vue from "vue";
import * as Sentry from "@sentry/browser";
import $axios from "@/plugins/axios";
import Slideover from "./components/slideover/Index";
import Modal from "./components/modal/Modal";
import ModalShell from "./components/modal/Shell";
import NAccordion from "./components/navigator/Accordion";
import Layers from "./components/navigator/Layers";

Vue.component("Slideover", Slideover);
Vue.component("Modal", Modal);
Vue.component("ModalShell", ModalShell);

/**
 * @pagemakerhq/segments package expects the inline editor globally available.
 */
import InlineEditor from "@/components/inspector/segment/text/InlineEditor.vue";
Vue.component("PmTextInlineEditor", InlineEditor);

/**
 * Navigator Components
 */
Vue.component("NAccordion", NAccordion);
Vue.component("Layers", Layers);

Vue.prototype.$sentry = {
  catch: (err) => {
    Sentry.captureException(err);
  },
};

Vue.prototype.$axios = $axios;
